import AbstractService from '@/services/abstractService'

class WalkThroughService extends AbstractService {
  endpoint = 'walkThrough'

  put(id, data, params = null) {
    // define append form
    const formData = new FormData()

    for (const key in data) {
      if (key === 'image' && data[key] === undefined) {
        continue
      }
      formData.append(key, data[key])
    }
    return this.http.put(`${this.endpoint}/${id}`, formData, params)
  }
}

const Service = new WalkThroughService()

export default Service
